.slideIn {
    animation-duration: 500ms;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        transform: translateX(110%);
    }

    to {
        transform: translateX(0%);
    }
}

.slideOut {
    animation-duration: 500ms;
    animation-name: slideout;
}

@keyframes slideout {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(110%);
    }
}
